<template>
  <div>
    <div v-for="permission, rule in rules" :key="rule" :class="{'inactive': !(permission * 1)}" class="rule">
      <h5 class="flix-html-h5">{{ $tc('message.' + rule, 2) }}</h5>
      <div v-for="option, index in options" :key="option + rule" class="label">
        <flixIcon v-if="index * 1 && index * 1 < permission" id="ok" />
        <flixIcon v-else-if="index * 1 == permission" id="ok" />
        <flixIcon v-else id="unchecked" />
        {{ option }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    variant: {
      type: String,
      default () { return 'abonnement' }
    }
  },
  data () {
    return {
      rules: require('./json/' + this.variant + '.json'),
      options: [
        this.$t('message.no', { name: '' }),
        this.$t('message.show', { name: '' }),
        this.$t('message.edit', { name: '' }),
        this.$t('message.createNew', { name: '' })
      ]
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
  .variant
    text-transform: uppercase
    font-weight: bold
  h3
    margin-top: 5px
    text-transform: uppercase
    margin-bottom: 30px
  h5
    text-transform: uppercase
    flex: 1
    text-align: left
  .rule
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    gap: 12px
    flex-wrap: wrap
  @media (max-width: 700px)
    .rule
      flex-direction: column
      align-items: flex-start
      margin-bottom: 30px
  .label
    display: flex
    align-items: center
    gap: 2px
</style>
